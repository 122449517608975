.roles {
  h5 {
    font-size: 1.17em;
    font-weight: bold;
    margin: 1em 0;
  }

  h6 {
    font-size: 1.1em;
    font-weight: bold;
  }

  .crew {
    margin: 0.5em;
  }
}
