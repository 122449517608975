nav {
  border-bottom: 1px solid #6688ad;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  padding: 0 1.75rem;
  height: 4rem;

  & .logo {
    max-height: 2.5rem;
    max-width: 4.5rem;

    @media (max-width: 1025px) {
      display: none;
    }
  }

  & .right {
    height: 100%;
    display: flex;

    @media (max-width: 1025px) {
      display: none;
    }
  }
  & .right > :first-of-type {
    margin-right: 1rem;
  }
  .divider {
    margin-top: 0.5rem;
    height: 3rem;
  }
  .info-block-wrapper {
    height: 100%;
    display: flex;

    @media (max-width: 1025px) {
      display: none;
    }

    .infoblock {
      display: flex;
      justify-content: space-between;
      width: 360px;
      height: 100%;
      align-items: center;
      position: relative;
      padding-right: 10px;

      .infoblock-column {
        padding: 0 16px;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 60%;
        label {
          color: grey;
          text-transform: uppercase;
          font-size: 0.8rem;
        }
        p {
          font-weight: bold;
        }
      }
      .option-list {
        box-shadow: 0px 2px 10px -3px rgba(0, 0, 0, 0.25);

        background-color: white;
        z-index: 2000;
        position: absolute;
        top: 4rem;
        left: 0;
        padding-top: 0;
        width: 100%;
        .option-list-item {
          height: 2.5rem;
        }
      }
    }
  }

  .mobile-item {
    @media (min-width: 1025px) {
      display: none;
    }
  }
}
