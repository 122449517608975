@import "../../pages/categories/index.scss";

.add-crew-wrapper {
  display: flex;
  flex-direction: column;
  .field {
    margin-bottom: 1rem;
    //width: 32rem;
  }
}
