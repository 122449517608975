@import "../../pages/categories/index.scss";

.edit-crew-wrapper {
  display: flex;
  flex-direction: column;
  .field {
    margin-bottom: 1rem;
    //width: 32rem;
  }
  .change-status {
    margin-bottom: 1rem;
    padding-left: 0.8rem;
  }
}
