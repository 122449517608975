.tools {
  background: white;
  border-radius: 9px;
  position: fixed;
  padding: 6px;
  z-index: 2000;
  display: flex;
  flex-direction: column;

  &.left {
    top: 133px;
    margin-left: 5px;

    .fullscreen & {
      top: 17px;
    }
  }

  &.right {
    top: 60px;
    right: 0;

    .fullscreen & {
      top: 10px;
    }

    .MuiTextField-root {
      &:not(:last-child) {
        margin-bottom: 5px;
      }

      &,
      & input {
        width: 140px;
      }

      .MuiOutlinedInput-adornedEnd {
        padding-right: 0;
      }
    }
  }
}

@media print {
  .tools,
  .header-wrapper,
  .sidebar-wrapper {
    display: none;
  }
}
