@import "../../pages/categories/index.scss";
.add-position {
  display: flex;
  .add-field {
    margin-right: 1rem !important;
    width: 20rem;
  }
  .select-category {
    width: 20rem;
    margin-right: 1rem !important;
  }
}
