@import "../../pages/categories/index.scss";
.edit-operation {
  display: flex;
  .edit-field {
    margin-right: 1rem !important;
    width: 20rem;
  }
  .select-position {
    width: 20rem;
    margin-right: 1rem !important;
  }
}
