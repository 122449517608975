@import "../../pages/categories/index.scss";
.add-title {
  display: flex;
  .add-field {
    margin-right: 1rem !important;
    width: 20rem;
  }
  .select-source {
    width: 20rem;
    margin-right: 1rem !important;
  }
}
