.list-item {
  border-bottom: 1px solid black;
  .list-item-text {
    font-weight: 700;
    text-transform: uppercase;
    &.subitem {
      font-weight: normal;
      text-transform: capitalize;
    }
  }
  &.active {
    background: rgba(#536b86, 0.15);
  }
}

.app.links {
  margin-top: 50px;
}
