.hamburger-menu {
  cursor: pointer;
}

.sidebar-wrapper {
  transition: all 0.5s ease;
}

.logo-wrapper {
  display: none;
}

.menu-divider {
  display: none;
}

.d-none {
  display: none !important;
}

@media (max-width: 599.98px) {
  .sidebar-wrapper {
    max-width: 300px !important;
    width: 100%;
    left: -300px !important;
  }

  .sidebar-wrapper.active {
    left: 0 !important;
  }

  .user-title {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  .search-list {
    right: -100px !important;
  }

  .css-1sra7t5-MuiTypography-root {
    font-size: 36px !important;
    text-align: center;
  }
}

@media (max-width: 1025px) {
  nav {
    height: 80px !important;
  }

  .profile-item .MuiMenu-paper {
    right: 20px !important;
    top: 50px !important;
  }

  .mobile-item {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
  }

  .search-block {
    height: 100%;
    position: relative;
  }

  .search-list {
    box-shadow: 0px 2px 10px -3px rgb(0 0 0 / 25%);
    background-color: white;
    z-index: 2000;
    position: absolute;
    top: 4rem;
    right: 0;
    padding-top: 0;
    width: 350px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .search-block .search-list .option-list-item {
    padding: 10px 20px !important;
    margin: 0px !important;
  }

  .logout .MuiSvgIcon-root {
    color: #676767 !important;
  }

  .css-720yt7-MuiButtonBase-root-MuiListItem-root:hover
    .logout
    .MuiSvgIcon-root {
    color: #fff !important;
  }

  .css-720yt7-MuiButtonBase-root-MuiListItem-root:hover .MuiSwitch-thumb {
    background-color: #fff !important;
  }

  .css-720yt7-MuiButtonBase-root-MuiListItem-root:hover .svg-icon * {
    stroke: #fff;
  }

  .profile-menu {
    padding: 2px !important;
  }

  .css-h4y409-MuiList-root {
    overflow-y: unset !important;
  }

  .sidebar-wrapper {
    position: fixed;
    top: 0;
    max-width: 600px;
    background: #48535f;
    /* padding: 20px; */
    width: 100%;
    left: -600px;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 10000;
  }

  .sidebar-wrapper.active {
    left: 0;
  }

  .side-bar .list-item {
    padding: 10px 40px !important;
    margin: 0 !important;
  }

  .side-bar .MuiTypography-h4 {
    color: #dee0ea;
    margin-left: 40px;
  }

  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;

    .close-icon {
      cursor: pointer;
    }
  }

  .app.links {
    margin: 40px 0;
  }

  .list-item .list-item-text {
    font-weight: 400 !important;
    color: #fff !important;
  }

  .MuiListItemIcon-root svg * {
    fill: #fff;
  }

  svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    color: #fff;
  }

  hr.MuiDivider-root.MuiDivider-fullWidth.css-9mgopn-MuiDivider-root {
    display: none;
  }

  .menu-divider {
    display: block;
  }

  .input-wrapper {
    height: 100% !important;
  }

  .user-title {
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;
    color: #1e2420;
  }

  .search-filed {
    width: 85% !important;
  }

  // .WithStyles\(ForwardRef\(TextField\)\)-root-2 input {
  //   height: 40px !important;
  // }

  .MuiOutlinedInput-input {
    height: 40px !important;
  }

  .WithStyles\(ForwardRef\(TableHead\)\)-root-7 {
    background-color: transparent !important;
  }

  thead.MuiTableHead-root {
    background: transparent !important;
  }

  tr.MuiTableRow-root.MuiTableRow-head {
    background-color: #fff !important;
  }

  tr.MuiTableRow-root:nth-child(1n) {
    background: #f0f0f5;
    border-radius: 12px;
  }

  tr.MuiTableRow-root:nth-child(2n) {
    background: #fafafa;
    border-radius: 12px;
  }

  .custom-row {
    display: grid !important;
    grid-template-columns: 5% 20% 30% 25% 10% 10%;
    align-items: center;
    overflow: hidden;
    margin: 10px 0;
    padding: 20px 10px;
  }

  .t-none {
    display: none !important;
  }

  .d-none {
    display: block !important;
  }

  .MuiTableCell-head.MuiTableCell-sizeSmall:nth-child(6) {
    display: none !important;
  }

  .MuiTableCell-head.MuiTableCell-sizeSmall:nth-child(7) {
    display: none !important;
  }

  .MuiTableCell-root {
    border: 0 !important;
  }

  .MuiListItem-button {
    margin: 5px 10px !important;
    padding: 0 15px 0 0 !important;
    border-radius: 4px !important;
  }

  // .css-720yt7-MuiButtonBase-root-MuiListItem-root {
  //   min-height: 42px !important;
  // }

  .css-720yt7-MuiButtonBase-root-MuiListItem-root:hover {
    background: #536b86 !important;
    color: #fff !important;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #21d3a0 !important;
  }

  .m-btn {
    height: 38px !important;
    width: 38px !important;
  }

  .MuiTableContainer-root {
    border: none !important;
  }

  .MuiTablePagination-toolbar {
    justify-content: center !important;
    margin: 30px 0;
  }

  .MuiTablePagination-spacer {
    display: none !important;
  }

  .MuiTablePagination-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .Mui-disabled {
    color: #151b28;
    border: 1px solid #dee0ea !important;
    border-radius: 4px;
    padding: 10px !important;
    background-color: transparent !important;
  }

  button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit.MuiIconButton-sizeMedium.css-1drqkcs-MuiButtonBase-root-MuiIconButton-root {
    background: #536b86;
    border-radius: 4px;
    padding: 10px !important;
  }

  .Mui-disabled .MuiSvgIcon-root {
    color: #151b28 !important;
  }

  .MuiTablePagination-displayedRows {
    border: 1px solid #dee0ea;
    padding: 12px;
    border-radius: 4px;
  }

  .MuiTablePagination-selectLabel {
    border: 1px solid #dee0ea;
    padding: 12px;
    border-radius: 4px;
  }

  .MuiSelect-select.MuiSelect-select {
    border: 1px solid #dee0ea;
    padding: 12px;
    border-radius: 4px;
  }

  .MuiSelect-icon {
    color: #536b86 !important;
  }

  .MuiTablePagination-menuItem {
    padding: 2px 5px !important;
  }

  // Scrollbar
  .scrollbar {
    overflow-y: scroll;
    margin-bottom: 25px;
  }

  ::-webkit-scrollbar {
    width: 3px;
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #e8e8e8;
  }
}

@media (max-width: 991px) {
}
