@import "../../pages/categories/index.scss";

.header-panel {
  display: flex;
  justify-content: space-between;
  height: $header-height;
  padding: 1rem 0.5rem;
  align-items: center;
  .search-input input {
    padding: 0.25rem 0.5rem;
  }
}
