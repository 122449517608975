@import "../node_modules/@fontsource/roboto/index.css";
@import "./reset.scss";
@import "./style.scss";

html,
body {
  color: #656b74;
  font-size: 14px;
  font-family: Roboto;
}
