@import "../../pages/categories/index.scss";
.add-custom-course {
  display: flex;
  flex-direction: column;
  .add-field,
  .select-course-period,
  .select-buffer {
    width: 40%;
    margin-bottom: 1rem;
  }
  .change-status {
    padding-left: 0.5rem;
  }
  .add-icon {
    width: 3rem;
    height: 3rem;
    border-radius: 0;
  }
  .position-period-row-wrapper {
    width: 100%;
    margin-bottom: 1rem;

    .position-period-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .select-position,
      .select-period {
        width: 45%;
      }
      .delete-icon {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 0;
      }
    }
  }
}
